import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/node_modules/next/font/google/target.css?{\"path\":\"../../packages/ui/Utils/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/node_modules/next/font/google/target.css?{\"path\":\"../../packages/ui/Utils/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/node_modules/swiper/swiper.min.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/node_modules/swiper/modules/navigation/navigation.min.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/apps/incluipcd/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/node_modules/@flaticon/flaticon-uicons/css/bold/rounded.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/node_modules/@flaticon/flaticon-uicons/css/brands/all.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/packages/ui/IncluiPCD/LiveUpdater.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/packages/ui/IncluiPCD/NotificationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/egalite-incluipcd/egalite-incluipcd/packages/ui/IncluiPCD/ThemeProvider.tsx");
