'use client';

import defaultTheme from '@egalite/theme/default';
import { ThemeProvider as AppThemeProvider } from '@emotion/react';
import { FC, ReactNode } from 'react';

type Props = {
	children?: ReactNode;
};

const ThemeProvider: FC<Props> = ({ children }) => {
	return <AppThemeProvider theme={defaultTheme}>{children}</AppThemeProvider>;
};

export default ThemeProvider;
