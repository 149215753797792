const defaultTheme = {
	colors: {
		white: '#fff',
		black: '#000',
		transparent: 'transparent',
		brand: {
			main: '#0F1DD7',
			dark: '#05318E',
			light: '#D5E5FA',
			superlight: '#E8F2FF',
		},
		base: {
			'grey-1': '#FAFAFA',
			'grey-2': '#F2F2F2',
			'grey-3': '#EDEDED',
			'grey-4': '#686F86',
			'grey-5': '#2E2E2E',
			'grey-6': '#3E4050',
			'grey-7': '#716688',

			// verificar se esses valores são usados
			'dark-grey-1': '#C6C6C6',
			'dark-grey-3': '#E8E8ED',

			// depreciar e usar o light e superlight do brand
			'purple-1': '#F5F0FF',
			'purple-2': '#E1D5FA',
			'purple-3': '#5844A8',
		},
		accent: {
			main: '#25C7D0',
			light: '#1DC7D0',
			lightest: '#C9F7EE',
		},
		error: {
			dark: '#841500',
			light: '#FDF7F7',
		},
		success: {
			dark: '#006268',
			light: '#1DC7D0',
		},
		other: {
			blue: '#0061FD',
			green: '#2E7D32',
			orange: '#E64A19',
		},
		gradient: {
			horizontal: 'linear-gradient(90deg, rgba(44,30,91,1) 10%, rgba(44,30,91,0) 60%);',
			vertical: 'linear-gradient(0deg, rgba(44,30,91,1) 21%, rgba(44,30,91,0) 50%);',
		},
	},
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl'],
		values: { xs: 1, sm: 760, md: 1024, lg: 1360, xl: 1600 },
		down(e: keyof typeof this.values | number) {
			const t = typeof e === 'number' ? e : this.values[e];

			return '@media (max-width: '.concat((t - 1).toString()).concat('px )');
		},
		up(e: keyof typeof this.values | number) {
			const t = typeof e === 'number' ? e : this.values[e];

			return '@media (min-width: '.concat(t.toString()).concat('px )');
		},
	},
	grid: {
		container: { xs: 'full', sm: 64, md: 96, lg: 124, xl: 150 },
		padding: { xs: 3, sm: 3, md: 3, lg: 3, xl: 3 },
		columns: { xs: 4, sm: 4, md: 12, lg: 12, xl: 12 },
		gutter: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
		breakpoints: { xs: 1, sm: '760px', md: '1024px', lg: '1360px', xl: '1600px' },
		//debug colors
		colors: {
			blue: '#0470F5',
		},
	},
	typography: {
		defaults: {
			marginBottom: '2rem',
			color: 'base.grey-5',
		},
		get h1() {
			return {
				fontFamily: 'Inter, sans-serif',
				fontSize: '4.8rem',
				fontWeight: 700,
				letterSpacing: 0,
				lineHeight: 1,
				color: 'base.grey-5',
				[defaultTheme.breakpoints.down('sm')]: {
					fontSize: '4rem',
				},
			};
		},
		get h2() {
			return {
				fontFamily: 'Inter, sans-serif',
				fontSize: '4.2rem',
				fontWeight: 700,
				letterSpacing: 0,
				lineHeight: 1.3,
				color: 'base.grey-5',
				[defaultTheme.breakpoints.down('sm')]: {
					fontSize: '3.6rem',
				},
			};
		},
		get h3() {
			return {
				fontFamily: 'Inter, sans-serif',
				fontSize: '3.2rem',
				fontWeight: 700,
				letterSpacing: 0,
				lineHeight: 1,
				[defaultTheme.breakpoints.down('sm')]: {
					fontSize: '1.8rem',
				},
			};
		},
		h4: {
			fontFamily: 'Inter, sans-serif',
			fontSize: '2.8rem',
			fontWeight: 700,
			letterSpacing: 0,
			lineHeight: 1,
		},
		h5: {
			fontFamily: 'Inter, sans-serif',
			fontSize: '2.2rem',
			fontWeight: 700,
			letterSpacing: 0,
			lineHeight: 1,
		},
		h6: {
			fontFamily: 'Inter, sans-serif',
			fontSize: '1.8rem',
			fontWeight: 700,
			letterSpacing: 0,
			lineHeight: 1,
		},
		subheadline1: {
			fontFamily: 'Roboto, sans-serif',
			fontSize: '2.2rem',
			fontWeight: 700,
			letterSpacing: 0,
			lineHeight: 1,
			color: 'brand.main',
		},
		subheadline2: {
			fontFamily: 'Roboto, sans-serif',
			fontSize: '1.833rem',
			fontWeight: 700,
			letterSpacing: 0,
			lineHeight: 1,
			color: 'base.grey-4',
		},
		subheadline3: {
			fontFamily: 'Roboto, sans-serif',
			fontSize: '1.6rem',
			fontWeight: 700,
			letterSpacing: 0,
			lineHeight: 1,
			color: 'brand.main',
		},
		subheadline4: {
			fontFamily: 'Roboto, sans-serif',
			fontSize: '1.3rem',
			fontWeight: 700,
			letterSpacing: 0,
			lineHeight: 1,
		},
		body1: {
			fontFamily: 'Roboto, sans-serif',
			fontSize: '2.2rem',
			fontWeight: 400,
			letterSpacing: 0,
			lineHeight: 1.5,
			color: 'base.grey-5',
		},
		body2: {
			fontFamily: 'Roboto, sans-serif',
			fontSize: '1.8rem',
			fontWeight: 400,
			letterSpacing: 0,
			lineHeight: 1.5,
			color: 'base.grey-5',
		},
		body3: {
			fontFamily: 'Roboto, sans-serif',
			fontSize: '1.6rem',
			fontWeight: 400,
			letterSpacing: 0,
			lineHeight: 1.31,
			color: 'base.grey-5',
		},
		button: {},
		input: {
			get label() {
				return {
					fontFamily: 'Roboto, sans-serif',
					fontSize: '1.8rem',
					fontWeight: 500,
					lineHeight: 1.5,
					marginBottom: 8,
					display: 'flex',
					justifyContent: 'space-between',
					[defaultTheme.breakpoints.down('md')]: {
						display: 'block',
					},
					'.optional': {
						fontWeight: 400,
						marginLeft: 12,
					},
					color: defaultTheme.colors.base['grey-5'],
				};
			},
			get field() {
				return {
					fontFamily: 'Roboto, sans-serif',
					fontSize: '1.8rem',
					fontWeight: 400,
					backgroundColor: defaultTheme.colors.white,
					color: defaultTheme.colors.base['grey-5'],
					['::placeholder']: {
						color: defaultTheme.colors.brand.dark,
						opacity: 0.6,
					},
				};
			},
		},
	},
};

export default defaultTheme;
export type DefaultTheme = typeof defaultTheme;
